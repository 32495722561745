export const useApolloFetch = (query: any, resultDataPropName: string) => {
  const client = useApolloClient().client
  const { $normalize } = useNuxtApp()

  const isLoading = ref(false)

  const execute = async (variables?: any) => {
    isLoading.value = true
    const { data } = await client.query({
      query,
      variables
    })
    isLoading.value = false
    if (!data) {
      return null
    }
    return $normalize(data[resultDataPropName])
  }

  return { execute, isLoading }
}
